<script lang="ts">
  import StarOutline from "svelte-material-icons/StarOutline.svelte";
  import StarHalf from "svelte-material-icons/StarHalf.svelte";
  import Star from "svelte-material-icons/Star.svelte";

  export let point;
  export let digits: number = 2;
  export let maxPoint: number = 5;
  export let starSize = "1em";
  export let className = "text-secondary-main";
  export let isHidden = false; // if hidden, replace number with dash

  let pointNum = parseFloat(point);
  let pointStr = pointNum.toFixed(digits);

  let fullStarCount = Math.floor(pointNum);
  let halfStarCount = (pointNum - fullStarCount) >= 0.5 ? 1 : 0;
  let nullStarCount = maxPoint - (fullStarCount + halfStarCount);
</script>

<span class="flex items-center {className}">
  {#each {length: fullStarCount} as _, i}
    <Star size={starSize} />
  {/each}
  {#if halfStarCount == 1}
    <StarHalf size={starSize} />
  {/if}
  {#each {length: nullStarCount} as _, i}
    <StarOutline size={starSize} />
  {/each}
  {#if isHidden}
    {pointStr.replace(/\d/g, "-")}
  {:else}
    {pointStr}
  {/if}
</span>
